@font-face {
  font-family: "양진체";
  src: url("https://fastly.jsdelivr.net/gh/supernovice-lab/font@0.9/yangjin.woff")
    format("woff");
  font-weight: normal;
  font-style: normal;
}

.title-section {
  font-family: "양진체";
}
.submit-button {
  font-family: "양진체";
}
.form-section {
  font-family: "양진체";
}
.inquiry-form-title {
  font-family: "양진체";
}
.inquiry-form-sub-title {
  font-family: "양진체";
}
.consult-button {
  font-family: "양진체";
}

body {
  margin: 0;
  padding: 0;
  background-color: #000000;
}

.title-section {
  display: flex;
  flex-direction: column; /* 세로로 정렬 */
  align-items: center; /* 가로 중앙 정렬 */
  justify-content: center; /* 세로 중앙 정렬 */
  text-align: center; /* 텍스트를 중앙 정렬 */
  height: 100vh; /* 부모 높이를 화면 전체로 설정 */
  margin: 0;
  padding: 0;

  height: 300px;
  background-color: #f8f7f3;
  text-align: center;
  margin-bottom: 2rem;
  padding: 1rem 0;
}

.car-inquiry-wrapper {
  display: flex;
  justify-content: center;
  background-color: #333;
}

.car-inquiry-form {
  font-family: Arial, sans-serif;
  /* background-color: #f8f7f3; */
  background-color: #f0f0f0;
  min-height: 100vh;
  width: 100%;
  max-width: 580px;
  overflow-x: hidden;
  position: relative;
}

.header {
  background-color: #3b5847;
  color: white;
  padding: 1rem;
  text-align: center;
  height: 15px;

  /* Flexbox를 사용해 중앙 정렬 */
  display: flex;
  justify-content: center; /* 가로 중앙 정렬 */
  align-items: center; /* 세로 중앙 정렬 */
}

.footer {
  background-color: #3b5847;
  color: white;
  padding: 1rem;
  /* text-align: center; */
  font-size: 13px;
  height: 160px;
}

.content-container {
  padding: 0 1rem;
}

.main-title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.check-icon {
  color: red;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  border-top: 0.5rem;
  font-size: 1.5rem;
}

.sub-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}
.sub-title span {
  font-size: 2.2rem;
  font-weight: bold;
  margin-bottom: 3rem;
}

.description {
  font-size: 0.9rem;
  color: #666;
}

.emoji {
  font-size: 2rem;
  margin-left: 0.5rem;
}

.info-box {
  text-align: center;
  margin-bottom: 3rem;
}

.info-box h3 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.info-box p {
  font-size: 0.8rem;
  color: #666;
}

.inquiry-form {
  margin-bottom: 4rem;
}

.form-group {
  margin-top: 25px;
  margin-bottom: 1rem;
}
.form-group-flex {
  margin-bottom: 1rem;
  display: flex;
  gap: 7px;
  width: 100%;
  margin-bottom: 5px;
}

label {
  display: block;
  margin-bottom: 0.5rem;
}

input[type="tel"],
input[type="text"],
textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box;
}

.button-group {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.2rem;
  margin-top: 15px;
}

.button-group button {
  padding: 0.5rem;
  border: 1px solid #3b5847;
  background-color: transparent;
  cursor: pointer;
  border-radius: 8px;
  font-weight: bold;
  font-size: 0.9rem;
  height: 40px;
  color: #3b5847;
}

.button-group button.active {
  background-color: #3b5847;
  color: white;
}

.terms-section {
  margin-bottom: 1rem;
  margin-left: 0.3rem;
}

.font-bold {
  font-weight: bold;
}

.submit-button {
  width: 100%;
  background-color: transparent; /* Remove background */
  border: 2px solid #3b5847; /* Set the border color and thickness */
  color: #3b5847; /* Text color, matching the border */
  padding: 1rem;
  font-size: 0.3rem;
  cursor: pointer;
  text-align: center;
  outline: none;
  border-radius: 8px;
  margin-top: 15px;
  margin-bottom: 30px;
}
.submit-button:hover {
  background-color: #3b5847; /* Optional: background color on hover */
  color: white; /* Change text color on hover */
}

.experience-badge {
  text-align: center;
  margin-top: 2rem;
}

.badge-text {
  font-weight: bold;
  font-size: 1.5rem;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 5px;
}

.badge-text-accent {
  font-weight: bold;
  font-size: 1.7rem;
  color: #3b5847;
}

.fixed-bottom {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 580px;
  display: flex;
  padding: 0.5rem;
  margin-bottom: 5%;
  box-sizing: border-box;
}

.consult-button {
  flex: 1;
  padding: 0.75rem;
  border: 0.5px solid #333;
  font-size: 1rem;
  border-radius: 10px;
  cursor: pointer;
}

.consult-button + .consult-button {
  margin-left: 0.5rem;
}

.yellow {
  background-color: #f7ba2c;
  color: black;
}

.gray {
  background-color: #f0f0f0;
  color: black;
}

.inquiry-textarea {
  width: 100%;
  height: 6.25em;
  border: none;
  resize: none;
  border: 1px solid #ccc;
}

.form-label {
  font-size: 16px;
  padding-top: 10px;
  padding-left: 5px;
  width: 70px;
}

.form-input {
  flex: 1;
  padding: 8px 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 1px;
  background-color: transparent;
  height: 40px;
}

.icon1 {
  padding-top: 10px;
  margin-right: 5px;
  height: 30px;
  width: 30px;
}

.form-section {
  margin-top: 50px;
}

.accent {
  color: #3b5847;
}

.accent1 {
  color: #3e3b32;
}
.accent2 {
  color: #2d6a4f;
}
.accent3 {
  color: #9b2d20;
}
.accent4 {
  color: #1d3557;
}
.accent5 {
  color: #d4a017;
  line-height: 1.6;
}

.font-gray {
  color: gray;
}

.font-red {
  color: red;
}

.rotated {
  display: inline-block; /* Allows transformation */
  transform: rotate(-6deg); /* Rotates the letter */
  transform-origin: center; /* Ensures rotation is centered */
}

.underline {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 3px;
}

.fontup {
  font-size: 1.4rem;
}

.experience-badge p {
  color: #333;
  font-size: 0.9rem;
  line-height: 1.6;
}

.custom-checkbox-container {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.custom-checkbox-input {
  display: none;
}

.custom-checkbox-label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.custom-checkbox-box {
  margin-top: 2px;
  width: 15px;
  height: 15px;
  border: 2px solid #ccc;
  border-radius: 4px;
  margin-right: 8px;
  position: relative;
  display: inline-block;
}

.custom-checkbox-input:checked + .custom-checkbox-label .custom-checkbox-box {
  background-color: #3b5847; /* 선택 시 색상 */
  border-color: #3b5847;
}

.custom-checkbox-box::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 3.5px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
  transition: opacity 0.2s;
}

.custom-checkbox-input:checked
  + .custom-checkbox-label
  .custom-checkbox-box::after {
  opacity: 1;
}

.term-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}

.term-item .arrow {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #888;
  font-size: 14px;
  flex-shrink: 0;
}
