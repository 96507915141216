.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.dialog-content {
  background-color: white;
  padding: 1rem;
  border-radius: 8px;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: auto;
}

.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.dialog-body {
  font-size: 0.9rem;
}

.dialog-body table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
}

.dialog-body th,
.dialog-body td {
  border: 1px solid #ccc;
  padding: 0.5rem;
  text-align: left;
}

.dialog-body th {
  background-color: #f0f0f0;
}
